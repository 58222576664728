import React from "react";
import "./AddContactPopUp.css";
import { Dialog } from "@mui/material";
import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Box,
  styled,
  TextField,
  Typography
} from "@mui/material";
import "@fontsource/montserrat";
import { addContactList, editContactList } from "../services/ContactServices";
import { apiResponseCodes, invalidToken, localStorageNames, POP_TYPES } from "../../../shared/utils/Constants";
import { colorCodes, fontFamily } from "../../../shared/styles/styles.config";
import { getStorageValue, signOut } from "../../../shared/utils/common";

const StyledTextField = styled(TextField)`
  width: 100%;
  color: ${colorCodes.dustyGray};
  opacity: "0.4";
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: ${colorCodes.burntSienna};        
    }
  }
  @media (max-width: 900px) {
    minWidth: 100%;      
  }
`;

const StyledInputsHolder = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  & > div {
    margin-bottom: 1rem;
  }
`;

const StyledRadioBtnsHolder = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledPhoneHolder = styled(Box)`
  & .flag-dropdown {
    padding: 1rem 0.5rem;
  }

  // Opned dropdown
  & .country-list{
    left: 0; 
    top: 3rem;
    max-height: 150px;
  }

  & .search-box{
    padding: 0.75rem !important;
    width: 90%;
    /* flex: 1; */
  }

  & .country{
    padding: 0.75rem !important;
  }

  & .form-control{
    width: 100%;
    padding-top: 14px;
    padding-bottom: 1rem;
    padding-left: 4rem;
    height: auto;
  }

  @media (max-width: 400px) {
    & .country-list {
      width: 250px;
    }
  }
`;

function ContactPopUp(props) {

  const { t } = useTranslation();
  const [name, setContactName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [phone, setPhone] = React.useState("")
  const [nameValidation, setNameValidation] = React.useState("");
  const [emailValidation, setEmailValidation] = React.useState("");
  const [phoneValidation, setPhoneValidation] = React.useState("")
  const [checkedField, setCheckedField] = React.useState(0);
  const [isEdit, setIsEdit] = React.useState(false)
  const regPhone = /^(?:[0-9] ?){6,19}[0-9]$/;
  const regEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; 
  const [prevContactDetail, setPrevContactDetail] = React.useState(""); 
  let continueNameFlag = false;
  let continueEmailFlag = false;   
  const loggedInUserMobile = JSON.parse(getStorageValue(localStorageNames.loginDetails))?.mobileNo;

  const StyledContinueBtn = styled(Button)`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    align-self: center;
    color: ${colorCodes.white};
    width: 200px;
    height: 55px;
    border-radius: 8px;
    padding: 1rem 0;
    @media (max-width: 900px) {
      width: 150%;
    }
`;

  const theme = createTheme({
    palette: {
      primary: {
        main: colorCodes.tango,
      },
    },
  });  

  React.useEffect(() => {
    if (props?.isEditContact?.name) {
      setContactName(props?.isEditContact?.name);
      setEmail(props?.isEditContact?.value); 
      setPrevContactDetail(props?.isEditContact?.value);
      setIsEdit(true)    
      setTitle(t("edit_contact_text"));
    }
    else {
      setTitle(t("add_contact_text"));
    }
  }, [props])

  const showPopUp = (msg: string, status?: boolean) => {
    props.setPopType(msg);
    props.setIsModelOpen(true);
    if (status) {
      setTimeout(() => {
        props.setNewData((current: boolean) => !current);
      }, 2000);
    }
  }

  const handleChangePhone = (phone) => {
    setPhone(phone);
  };

  const validateContactName = () => {
    let errorFlag = false;
    if (name?.length <= 0) {
      setNameValidation(t("name_length_validation_msg"));
      errorFlag = true;     
    }
    continueNameFlag = !errorFlag;
    return errorFlag;
  }

  const validateContactDetails = () => {
    let errorFlag = false;
  
    if (checkedField === 0) {
      // Email field validation
      if (!regEmail.test(email)) {
        setEmailValidation(t("email_validation_msg"));
        errorFlag = true;
      } else if (
        prevContactDetail !== email &&
        props?.contactData?.values?.includes(email)
      ) {
        setEmailValidation(t("duplicate_contact_detail_msg"));
        errorFlag = true;
      }
    } else if (checkedField === 1) {
      // Phone field validation
      if (!regPhone.test(phone)) {
        setPhoneValidation(t("phone_validation_msg"));
        errorFlag = true;
      } else if (
        prevContactDetail !== phone &&
        props?.contactData?.values?.includes(phone)
      ) {
        setPhoneValidation(t("duplicate_contact_detail_msg"));
        errorFlag = true;
      }
    }
  
    // Set the continue flags based on the errorFlag value
    continueEmailFlag = !errorFlag;
    // continuePhoneFlag = !errorFlag;
  
    // Reset validation messages when there are no errors
    if (!errorFlag) {
      setEmailValidation("");
      setPhoneValidation("");
    }
  
    return errorFlag;
  };
  
  

  const handleContact = () => {
    const data = checkedField === 0 ? {
      name,
      contactDetail:email
    } : {
      name,
      contactDetail: "+" + phone
    }

    setNameValidation("");
    setEmailValidation("");
    setPhoneValidation("");

    if (props?.isEditContact?.name) {
      validateContactName();
      if (continueNameFlag) {
        editContactList(data, loggedInUserMobile).then((res) => {
          if (res && res?.result?.code === apiResponseCodes?.success) {
            props.handleClose(false);
            props.setData(res?.result?.data);
            showPopUp(POP_TYPES?.SUCCESS, res?.status)
          } 
          else if (res?.result?.response?.status === apiResponseCodes.invalidRequest &&
                  res?.result?.response?.data === invalidToken) {          
            props.handleClose(false);
            showPopUp(POP_TYPES.ERROR, res?.status);
            props.setData(t("authentication_error_msg"));
            setTimeout(() => {
              signOut();
            }, 2000);
          }
          else {
            props.handleClose(false);
            showPopUp(POP_TYPES?.ERROR, res?.status);
            props.setData(t("update_contact_error"));
          }
        })
      }
    }
    else {
      validateContactName();
      validateContactDetails();
      if (continueNameFlag && continueEmailFlag) {
        addContactList(data, loggedInUserMobile).then((res) => {
          if (res && res?.result?.code === apiResponseCodes?.success) {
            props?.handleClose(false);
            props?.setData(t("add_contact_success_msg"));
            showPopUp(POP_TYPES?.SUCCESS, res?.status)
          } 
          else if (res?.result?.response?.status === apiResponseCodes.invalidRequest &&
                  res?.result?.response?.data === invalidToken) {          
            props.handleClose(false);
            showPopUp(POP_TYPES.ERROR, res?.status);
            props.setData(t("authentication_error_msg"));
            setTimeout(() => {
              signOut();
            }, 2000);
          }
          else {
            props?.handleClose(false);
            showPopUp(POP_TYPES?.ERROR, res?.status);
            props?.setData(t("add_contact_error"));
          }
        });
      }
    }
  }

  return (
    <Dialog
      open={props?.isModelOpen}
      onClose={props?.handleClose}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "80%",
            minWidth: "30%",
            borderRadius: "10px",
          },
        },
      }}
    >
      <div className="popup" style={{ alignItems: "center" }}>
        <Typography
          variant="h6"
          sx={{
            fontSize: "17px",
            fontWeight: "500",
            lineHeight: "19px",
            fontFamily: fontFamily?.Roboto
          }}>
          {title}
        </Typography><br />
        <StyledInputsHolder>
          <StyledTextField            
            placeholder={t("contact_name_placeholder_text")}
            variant="outlined"
            value={name}
            error={nameValidation?.length > 0 ? true : false}
            helperText={nameValidation}
            onChange={(e) => {
              setContactName(e?.target?.value);
            }}
          />
          {!isEdit && <StyledRadioBtnsHolder>
            {
              <>
                <input
                  type="radio"
                  id="emailRadio"
                  name="contactType"
                  checked={checkedField === 0}
                  onChange={() => setCheckedField(0)}
                />
                <label htmlFor="emailRadio">{t("email_text")}</label>
                <input
                  type="radio"
                  id="phoneRadio"
                  name="contactType"
                  checked={checkedField === 1}
                  onChange={() => setCheckedField(1)}
                />
                <label htmlFor="phoneRadio">{t("phoneText")}</label>
              </>
            }
          </StyledRadioBtnsHolder>}
          {checkedField == 0 || isEdit ?(
            <StyledTextField
              placeholder={t("email_text")}
              value={email}
              error={emailValidation?.length > 0 ? true : false}
              helperText={emailValidation}
              disabled={props?.isEditContact?.name ? true : false}
              onChange={(e) => {
                setEmail(e?.target?.value);
              }}
            />
          ) : (
            <StyledPhoneHolder>
              <PhoneInput
                country={"gb"}
                value={phone}
                placeholder={t("mobile_number")}
                onChange={handleChangePhone}
                autoFormat={false}
                countryCodeEditable={false}
                enableLongNumbers={true}
                enableSearch
              />
              <p style={{color:colorCodes.errorText,fontSize:"12px",paddingLeft:"1rem"}}>{phoneValidation.length > 0 && phoneValidation}</p>
            </StyledPhoneHolder>
          )}
        </StyledInputsHolder><br /><br />
        <Stack spacing={6} direction="column" alignSelf="center">
          <ThemeProvider theme={theme}>
            <StyledContinueBtn
              variant="contained"
              onClick={() => handleContact()}
            >
              {t("continue_button_text")}
            </StyledContinueBtn>
          </ThemeProvider>
          <button className="dismiss_btn" onClick={props?.handleClose}>
            {t("dismiss_button_text")}
          </button>
        </Stack>
      </div>
    </Dialog>
  )
}

export default ContactPopUp;