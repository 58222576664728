import React, { useState } from "react";
import Share_icon from "../../../shared/icons/shareBlack.svg";
import DownloadIcon from "../../../shared/icons/Download.svg";
import EditIcon from "../../../shared/icons/edit.svg";
import LockIcon from "../../../shared/icons/Lock.svg";
import Map from "../../../shared/components/GoogleMapsView";
import "./HistoryDetail.css";
import { useTranslation } from "react-i18next";
import { gpxFileDownloader } from "../../../shared/utils/GpxFileDownload";
import PopUp from "../../../shared/components/PopUp";
import { localStorageNames, POP_TYPES, trackingType } from "../../../shared/utils/Constants";
import Tooltip from "@mui/material/Tooltip";
import { colorCodes } from "../../../shared/styles/styles.config";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import { onMessageListener, requestForToken } from "../../../shared/components/FirebaseInit";
import { getStorageValue, isPrivateSession, isPublicSession, isSessionExpired, sortCoordinatesBasedOnTrackTime, timeDiff, toHoursAndMinutes } from "../../../shared/utils/common";
import GroupsIcon from "@mui/icons-material/Groups";
import { getTrackingSession } from "../services/ProfileServices";
import { isEmpty, isNil } from "lodash";
import { is_token } from "../../../shared/utils/Config";
import { getTrackingDetails } from "../../liveTracking/services/TrackingServices";
import NotificationAlertPop from "../../../shared/components/NotificatonAlertPop";
import HistoryDetailTable from "./HistoryDetailTable";

const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: colorCodes.startlingOrange,
          color: colorCodes.white,
          border: `1px solid ${colorCodes.startlingOrange}`
        },
      },
    },
  },
});

export default function HistoryDetail() {
  const [isModelOpen, setIsModelOpen] = useState<boolean | true>(true);
  const [popType, setPopType] = useState<string | "">("");
  const [liveTracking, setLiveTracking] = React.useState("");
  const [startPosition, setStartPosition] = React.useState({ lat: 0, lng: 0, trackTime: "" });
  const [centerPosition, setCenterPosition] = React.useState({ lat: 0, lng: 0 });
  const [endPosition, setEndPosition] = React.useState({ lat: 0, lng: 0, trackTime: "" });
  const [polyLines, setPolyLines] = React.useState([{ lat: 0, lng: 0, trackTime: "" }]);
  const [downloadStatus, setDownloadStatus] = useState<Boolean>(false)
  const [data, setData] = React.useState<string | "">("");
  const [trackingSessionData, setSessionData] = React.useState({});
  const { t } = useTranslation();
  const location = useLocation();
  const [newData, setNewData] = React.useState(false);
  const [sessionName, setSessionName] = React.useState<string | "">("");
  const [sessionType, setSessionType] = React.useState<string | "">("");
  const [frequency, setFrequency] = React.useState(0);
  const [refreshPage, setRefreshPage] = React.useState(false);
  const searchParams = new URLSearchParams(location?.search);
  const trackingId = searchParams.get("id");
  const [count, setCount] = React.useState(0);
  const [isNotificationAlert, setIsNotificationAlert] = React.useState(false);
  const [coordinates] = React.useState(location?.state?.session?.coordinates?.sort(sortCoordinatesBasedOnTrackTime));
  const [trackingStatus] = React.useState(location?.state?.session?.trackStatus);
  const [disableSwitch] = React.useState(isSessionExpired(location?.state?.session));
  requestForToken();
  const currentToken = getStorageValue(localStorageNames?.fcmToken);

  const unsubscribeFcmNotifications = async () => {
    try {
      const fcmUnsubscribe = {};
      fcmUnsubscribe["fcmToken"] = !isNil(currentToken) ? currentToken : is_token;
      fcmUnsubscribe["trackingId"] = trackingId;
      fcmUnsubscribe["type"] = "UNSUBSCRIBE";
      await getTrackingDetails(fcmUnsubscribe);
    } catch (err) {
      // handle errors
    }
  }

  React.useEffect(() => {
    if ("Notification" in window)
    {
      const notificationStatus = Notification?.permission;
      if (notificationStatus !== "granted" && liveTracking === trackingType?.open) {
        setIsNotificationAlert(true);
      }
    }
  }, [liveTracking]);

  React.useEffect(() => {
    getTrackingSession(trackingId).then((res) => {
      if (res && res?.status) {
        const response = res?.result?.timeline;
        const data = response?.coordinates.sort(sortCoordinatesBasedOnTrackTime);
        const lastIndex = data?.length - 1;
        const center = Math.floor(lastIndex / 2);        
        setCount(1);
        setSessionData(response);
        setFrequency(parseInt(response?.frequency));
        setCenterPosition({ lat: data[center]?.lat, lng: data[center]?.lng });
        setStartPosition({ lat: data[0]?.lat, lng: data[0]?.lng, trackTime: data[0]?.trackTime });
        if (lastIndex > 0)
        {
          setEndPosition({ lat: data[lastIndex]?.lat, lng: data[lastIndex]?.lng, trackTime: data[lastIndex]?.trackTime });
        }
        if (data?.length > 2) {
          setPolyLines(data?.slice(1, data?.length - 1));
        }
        setLiveTracking(response?.trackStatus);
        setSessionName(response?.sessionName);
        setSessionType(response?.trackingType);
        if (response?.trackStatus === trackingType?.close) {
          unsubscribeFcmNotifications();
        }
      }
    });
  }, [newData, refreshPage]);
  
  // To get the notification from fcm
  onMessageListener()
    .then((payload: any) => {
      if (payload) {
        const data = JSON.parse(payload?.data?.message);
        if (data?.trackingId === trackingId)
        {
          const sortedCoordinates = data?.coordinates.sort(sortCoordinatesBasedOnTrackTime)
          const trackingStatus = data?.trackStatus === trackingType?.close ? data?.trackStatus : trackingType?.open;
          const lastIndex = sortedCoordinates?.length - 1;
          const center = Math.floor(lastIndex / 2);
          if (data && sortedCoordinates?.length >= 2) {
            setCenterPosition(sortedCoordinates[center]);
            setStartPosition(sortedCoordinates[0]);
            if (lastIndex > 0)
            {
              setEndPosition(sortedCoordinates[lastIndex]);
            }
            if (sortedCoordinates?.length > 2) {
              setPolyLines(sortedCoordinates?.slice(1, sortedCoordinates?.length - 1));
            }
            setLiveTracking(trackingStatus);
            if (trackingStatus === trackingType?.close) {
              unsubscribeFcmNotifications();
            }
          }
        }
      }
    })
    .catch((err) => console.log("failed: ", err));
  
  React.useEffect(() => {
    // To unsubscribe the fcm notification while tracking page changes
    return (() => {
      if (count > 0) {
        unsubscribeFcmNotifications();
      }
    })
  }, [count])

  React.useEffect(() => {
    let notificationStatus = "";
    if ("Notification" in window)
    {
      notificationStatus = Notification?.permission;
    }
    const timeInterval = frequency * 60 * 1000;
    const refreshMap = setInterval(() => {
      if (notificationStatus !== "granted" && liveTracking === trackingType?.open) {
        setRefreshPage((current: boolean) => !current);
      } else {
        clearInterval(refreshMap);
      }
    }, timeInterval);

    return () => {
      clearInterval(refreshMap)
    };
  }, [liveTracking]);  

  const [sessionDetail, setSessionDetail] = useState({});

  const handleClose = (event, reason) => {
    setIsNotificationAlert(false);
    if (reason && reason === "backdropClick") 
      return;
    setIsModelOpen(false);
  }

  const handleFileDownload = (type: string) => {
    type === POP_TYPES?.DOWNLOAD && gpxFileDownloader([location?.state?.session?.coordinates], [sessionName], setDownloadStatus)
    setPopType(type);
    setIsModelOpen(true);
  };

  const handleSharePopUp = () => {
    setPopType(POP_TYPES?.SHARE);
    setIsModelOpen(true);
  }

  const handleEdit = (type) => {
    setSessionDetail({
      name: sessionName,
      type: isPublicSession(sessionType),
      trackingId: location?.state?.session?.trackingId
    });
    setPopType(type);
    setIsModelOpen(true);
  };

  return (
    <div className="single_history_container">
      <div className="single_history_data">
        {/* {disableSwitch && (
          <Stack className="session_expired_banner">
            {t("tracking_session_expired_text")}
          </Stack>
        )} */}
        <div className="session_detail_header">
          <p>
            {t("duration_header_text")}:
            {toHoursAndMinutes(
              location?.state?.session?.coordinates?.length > 1
                ? Math.round(
                  Math.min(
                    location?.state?.session?.duration,
                    timeDiff(
                      location?.state?.session?.coordinates[0].trackTime,
                      location?.state?.session?.coordinates[
                        location?.state?.session?.coordinates.length - 1
                      ].trackTime
                    )
                  )
                )
                : 0,
              t("hoursText"),
              t("minsText")
            )}
          </p>
          <p>{t("start_location_header_text")}:&nbsp;
            <span className="location_axis">
              {location?.state?.session?.coordinates[0]?.lat?.toString()?.substring(0, 9)}{","}{location?.state?.session?.coordinates[0]?.lng?.toString()?.substring(0, 9)}
            </span>
          </p>
          {location?.state?.session?.trackStatus === trackingType?.close ?
            <p>{t("end_location_header_text")}:&nbsp;
              <span className="location_axis">
                {location?.state?.session?.coordinates[location?.state?.session?.coordinates?.length - 1]?.lat?.toString()?.substring(0, 9)}{","}{location?.state?.session?.coordinates[location?.state?.session?.coordinates?.length - 1]?.lng?.toString()?.substring(0, 9)}
              </span>
            </p> : ""}
        </div>
        <div className="session_data">
          <div className="head_map_holder">
            <div className="head_section">
              <div className="head_section">
                <ThemeProvider theme={theme}>                
                  {isPublicSession(sessionType) &&
                    <Tooltip title={t("publicSession_text")} placement="top" arrow>
                      <GroupsIcon className="lock_icon" />
                    </Tooltip>
                  }
                  {isPrivateSession(sessionType) &&
                    <Tooltip title={t("privateSession_text")} placement="top" arrow>
                      <img src={LockIcon} alt={t("lock_text")} width="16px" />
                    </Tooltip>
                  }                  
                </ThemeProvider>
                <a title={sessionName} style={{paddingTop: "3px", paddingLeft: "15px"}}>{sessionName?.length > 20 ? sessionName?.substring(0,20) + "..." : sessionName}</a>
              </div>
              <div className="icons">
                <ThemeProvider theme={theme}>
                  <div className="grey_bg" >
                    {isPublicSession(sessionType) &&
                      <Tooltip title={t("share_session")} placement="top" arrow>
                        <img
                          className="share-icon-enabled"
                          src={Share_icon}
                          width="16px"
                          alt={t("share_text")}
                          onClick={handleSharePopUp}
                        />
                      </Tooltip>
                    }
                    {isPrivateSession(sessionType) &&
                      <Tooltip title={t("privateShareSession_text")} placement="top" arrow>
                        <img
                          className="disabled-share-icon"
                          src={Share_icon}
                          width="16px"
                          alt={t("share_text")}
                        />
                      </Tooltip>
                    }
                  </div>
                  {isPublicSession(sessionType) && 
                  <div className="grey_bg" onClick={() => handleFileDownload(POP_TYPES?.DOWNLOAD)}>
                    <Tooltip title={t("download_session_text")} placement="top" arrow>
                      <img className="hand_cursor" src={DownloadIcon} alt={t("download_text")} width="17px" />
                    </Tooltip>
                  </div>}
                  {isPrivateSession(sessionType) &&
                  <div className="grey_bg" >
                    <Tooltip title={t("privateDownloadSession_text")} placement="top" arrow>
                      <img className="disabled-share-icon" src={DownloadIcon} alt={t("download_text")} width="17px" />
                    </Tooltip>
                  </div>
                  }
                  <div className="grey_bg" onClick={() => handleEdit(POP_TYPES?.EDIT)}>
                    <Tooltip title={t("edit_session_text")} placement="top" arrow>
                      <img className="hand_cursor" src={EditIcon} alt={t("edit_text")} width="20px" height="19px" />
                    </Tooltip>
                  </div>
                </ThemeProvider>
              </div>
            </div>
            <div className="map-view">
              { !isEmpty(trackingSessionData) && startPosition?.lat !== 0 && startPosition?.lng !== 0 &&                
                <Map
                  centerPosition={centerPosition}
                  startPosition={startPosition}
                  endPosition={endPosition}
                  polyLines={polyLines as any}
                  isLiveTracking={liveTracking}
                  isChildren={undefined}
                />
              }
            </div>
          </div>
          <div className="shared_contacts">
            <div>
              <h2 className="contact-section-header">{t("contacts_shared_section_text")} - {isPublicSession(sessionType)? t("public_session"): t("private_session") }</h2>
              {(location?.state?.session?.trackingContactList) &&
                location?.state?.session?.trackingContactList?.length > 0 ?
                (location?.state?.session?.trackingContactList) && 
                location?.state?.session?.trackingContactList?.map((contact, i) => 
                  <p className="contacts-shared" key={i}>{contact?.name}</p>
                ) : <div className="no_contacts"> {t("no_shared_contacts_text")}</div>}
            </div>
            {/* This feature is disabled for now, it may be enabled later */}
            {/* <div className="default_shared_contacts">
              <h2 className="contact-section-header">{t("default_contacts_shared_section_text")}</h2>
              {sharedContacts.map((contact, i) => <p key={i}>{contact.name}</p>)}
            </div> */}
          </div>
        </div>
        <br/>
        <div className="live_table_holder">
          <HistoryDetailTable coordinates={coordinates} trackingStatus={trackingStatus}/>
          <br/>
        </div>
      </div>
      <PopUp
        isModelOpen={popType && isModelOpen}
        type={popType}
        handleClose={handleClose}
        downloadStatus={downloadStatus}
        sessionNames={location?.state?.sessionNames}
        sessionData={sessionDetail}
        disableSwitch={disableSwitch}
        message={data}
        setPopType={setPopType}
        setIsModelOpen={setIsModelOpen}
        setData={setData}
        setNewData={setNewData}
        trackingId={location?.state?.sessionTracking}
      />
      <NotificationAlertPop
        isModelOpen={isNotificationAlert}
        closeModel={handleClose}
      />
    </div>
  );
}
