import * as React from "react";
import TableContainer from "@mui/material/TableContainer";
import { Stack, styled } from "@mui/material";
import { colorCodes, fontFamily } from "../../../shared/styles/styles.config";
import { t } from "i18next";
import "./LiveTrackingDetails.css";
import Box from "@mui/material/Box";
import Paginate from "../../../shared/components/Paginate";
import { trackingType } from "../../../shared/utils/Constants";
import { getTranslatedMonth, isCoordinatesAreZero, monthNumberToTranslationMap } from "../../../shared/utils/common";

const StyledTableContainer = styled(TableContainer)`
  width: 35vw;  
  padding-left: 10px;
  overflow:hidden;   

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const StyledRow = styled(Stack)`
  flex-direction: row;
`;

const StyledHeadRow = styled(StyledRow)`
  height: 40px;
  font-weight: 500;  
  border-radius: 10px 10px 0 0;
  background-color: ${colorCodes.whiteSmoke};
  & *{
    color: ${colorCodes.bunker};
  }  
`;

const StyledContentRow = styled(StyledRow)`
  font-weight: 400;
  font-family: ${fontFamily.Roboto}; 
  & *{
      color: ${colorCodes.darkCharcoal};
    }  
  padding-top: 1em;
  padding-bottom: 1em;  
`;

const StyledContentStack_1 = styled(Stack)`
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  width: 10%;
  text-align: left;
  padding-left: 1em;
  padding-right: 1rem;
  white-space: normal;
`;

const StyledContentStack_2 = styled(Stack)`
  flex-direction: row;
  align-items: center;  
  font-size: 16px;
  text-align: left;
  width: 40%;
  padding-right: 1rem;
  white-space: normal;
`;

const StyledContentStack_3 = styled(Stack)`
  flex-direction: row;
  align-items: center;  
  text-align: left;
  font-size: 16px;
  width: 30%;  
  white-space: normal;
`;

export default function LiveTrackingDetails({coordinates, trackingStatus}) {
  const [recCount, setRecordCount] = React.useState(0);
  const [currentData, setCurrentData] = React.useState([]);
  const [rowCount, setRowCount] = React.useState(0);
  
  React.useEffect(()=>{
    setRecordCount(coordinates?.length);
    if (trackingStatus === trackingType?.open && coordinates?.length > 10)
    {
      showPage(Math.ceil(coordinates?.length / 10));   
    }
    else 
    {
      showPage(1);    
    }
  }, [coordinates]);

  const showPage = (value) => {
    const startPos = (value * 10 - 10);
    const endPos = (value * 10);
    setRowCount(startPos);
    setCurrentData(coordinates?.slice(startPos, endPos));
  }

  return (
    <Box>
      <StyledTableContainer>
        <StyledHeadRow>
          <StyledContentStack_1>{t("number_header_text")}</StyledContentStack_1>
          <StyledContentStack_2>{t("latitude_header_text")}, {t("longitude_header_text")}</StyledContentStack_2>          
          <StyledContentStack_3>{t("timestamp_header_text")}</StyledContentStack_3>
        </StyledHeadRow>
        {
          currentData && currentData?.map((row, i)=>{
            const currentMonthNumber = new Date(row?.trackTime)?.getMonth();
            const monthTranslationString = monthNumberToTranslationMap[currentMonthNumber];
            return <StyledContentRow key={i} className={(currentData.length - 1 === i) ? "" : "text-bottom"}>
              <StyledContentStack_1>{rowCount + i + 1}</StyledContentStack_1>                            
              {isCoordinatesAreZero(row?.lat,row?.lng) ?
                <StyledContentStack_2>{t("locationNotAvailableText")}</StyledContentStack_2> :
                <StyledContentStack_2>{row?.lat?.toString()?.substring(0, 9)}, <br/>{row?.lng?.toString()?.substring(0, 9)}</StyledContentStack_2>                }
              <StyledContentStack_3 style={{whiteSpace:"pre-line"}}>{getTranslatedMonth(row?.trackTime,t(monthTranslationString))}</StyledContentStack_3>
            </StyledContentRow>
          })
        }        
      </StyledTableContainer>  
      { recCount > 10 &&   
        <div className="tracking-pages">
          <Paginate 
            totalRecords={coordinates?.length} 
            rowsPerPage={10} 
            currentPage={showPage} 
            trackingStatus={trackingStatus}
          />
        </div>
      }
    </Box>
  );
}
