import * as React from "react";
import Box from "@mui/material/Box";
import LiveTrackingDetails from "./LiveTrackingDetails";
import ShareTracking from "./ShareTracking";
import { grey } from "@mui/material/colors";
import DownloadSession from "./DownloadSession";
import { styled, Typography } from "@mui/material";
import { colorCodes, fontFamily } from "../../../shared/styles/styles.config";
import { t } from "i18next";
import { trackingType } from "../../../shared/utils/Constants";
import { requestForToken } from "../../../shared/components/FirebaseInit";
import { useLocation } from "react-router-dom";
import { isNil } from "lodash";
import { sortCoordinatesBasedOnTrackTime } from "../../../shared/utils/common";

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;  
  padding: 1rem 2rem 2rem 4rem;
  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    padding: 1.5rem;
  }
`;

const StyledHeader = styled(Typography)`
  font-family: ${fontFamily.Roboto};
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  text-align: left;
  padding-left: 5.5rem; 
  padding-top: 2rem; 
  color: ${colorCodes.darkCharcoal};
  & .live_text {
    color: #E3612C;
  } 
  @media (max-width: 900px) {
    padding: 2rem 0 0 1.5rem;
  }
`;

export default function Main({updateLocation, trackingData, updatedFcmData}) {
  const [coordinates, setCoordinates] = React.useState([]);
  const [sessionName, setSessionName] = React.useState("");
  const [userId, setUserId] = React.useState("");
  const [sessionTrackingType, setSessionType] = React.useState("");
  const [trackingStatus, setTrackingStatus] = React.useState("");
  const [userName, setUserName] = React.useState("");

  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const id = searchParams.get("id");

  // Call to get the token form fcm 
  requestForToken();

  React.useEffect(() => {
    const updatedCoordinates = !isNil(updatedFcmData) ? updatedFcmData?.coordinates.length > trackingData?.coordinates.length ? updatedFcmData?.coordinates : trackingData?.coordinates : trackingData?.coordinates;
    const trackingState = !isNil(updatedFcmData) && (updatedFcmData?.trackStatus === trackingType?.close) ? updatedFcmData?.trackStatus : trackingData?.trackStatus;
    const sortedCoordinates = updatedCoordinates.sort(sortCoordinatesBasedOnTrackTime)
    setCoordinates(sortedCoordinates);
    setSessionName(trackingData?.sessionName);
    setUserId(trackingData?.userId);
    setUserName(trackingData?.userName);
    setTrackingStatus(trackingState);
    setSessionType(trackingData?.trackingType);
  }, [updateLocation, id, trackingData]);

  const userIdSign= /^\d+$/.test(userId) ? "+" : "";

  return (
    <>
      <StyledHeader>{userName?.length > 0 ? "" : userIdSign}{userName?.length > 0 ? `${userName} (${userIdSign + userId})` : userId}’s {t("session_text")} - <span title={sessionName}>{sessionName?.length > 20 ? sessionName?.substring(0,20) + "..." : sessionName}</span> {trackingStatus === trackingType?.open ? <span className="live_text"> - {t("live_tracking")}</span> : ""}</StyledHeader>
      <StyledBox
        sx={{
          color: grey[900],
        }}
      >
        <LiveTrackingDetails coordinates={coordinates} trackingStatus={trackingStatus}/>
        <ShareTracking sessionTrackingType={sessionTrackingType}/>
        <DownloadSession />
      </StyledBox>
    </>
  );
}
