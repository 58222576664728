import { initializeApp } from "firebase/app";
import { getMessaging, onMessage, getToken } from "firebase/messaging";
import { setStorageValue } from "../utils/common";
import { googleFcmConfig, vapIdKey } from "../utils/Config";
import { localStorageNames } from "../utils/Constants";

const app = initializeApp(googleFcmConfig);
const messaging = getMessaging(app);
export const requestForToken = async () => {
  return getToken(messaging, { vapidKey: vapIdKey })
    .then((currentToken) => {
      if (currentToken) {
        // Perform any other neccessary action with the token
        setStorageValue(localStorageNames.fcmToken,currentToken);
      }
    })
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
