import React, { useEffect } from "react";
import { styled, TextField, Typography } from "@mui/material";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { colorCodes } from "../styles/styles.config";
import { fontFamily } from "../styles/styles.config";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "@fontsource/montserrat";
import { t } from "i18next";
import "./EditSession.css";
import { editSessions } from "../../pages/profile/services/ProfileServices";
import { POP_TYPES, sessionType, apiResponseCodes, invalidToken } from "../utils/Constants";
import { signOut } from "../utils/common";

interface IEditProps {  
  checked: boolean;
  onChange: () => any;
  disableSwitch?: boolean;
}

const StyledTextField = styled(TextField)`
width: 100%;
font-family: ${fontFamily.Roboto};
opacity: "0.4";
& .MuiOutlinedInput-root {
  &.Mui-focused fieldset {
    border-color: ${colorCodes.burntSienna};        
  }
}
`;  

const StyledTypography = styled(Typography)`
font-family: ${fontFamily.Roboto};
width: 10rem;
`;

const StyledNoteTypography = styled(Typography)`
margin-top: 0.5rem;
font-family: ${fontFamily.Roboto};
color: ${colorCodes.brown};
font-size: 10px;
font-weight: 300;
line-height: 12px;
`;

const StyledUpdateButton = styled(Button)`
font-family: 'Montserrat';
font-style: normal;
font-weight: 300;
font-size: 16px;
line-height: 20px;
align-self: center;             
background-color: ${colorCodes.tango};
color: ${colorCodes.white};
`;

const StyledCancelButton = styled(Button)`
font-family: 'Montserrat';
font-weight: 500;
font-size: 16px;
line-height: 20px; 
background-color: ${colorCodes.black};         
color: ${colorCodes.whiteSmoke};
`;

const theme = createTheme({
  palette: {
    primary: {
      main: colorCodes.tango,
    },
  },
});

function EditSession({ sessionData, sessionNames, disableSwitch, closeModel, setPopType, setIsModelOpen, setData, setNewData}) {
  const [isPublic, setIsPublic] = React.useState<boolean | false>(false);
  const [nameValidation, setNameValidation] = React.useState("");
  const [sessionName, setSessionName] = React.useState(sessionData.name);
  const [focus, setFocus] = React.useState(false);
  const [prevSessionName, setPrevSessionName] = React.useState("");

  const StyledSwitch = styled((props : IEditProps) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      disabled={disableSwitch}
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#FF7410",
        "& + .MuiSwitch-track": {
          backgroundColor:
          theme.palette.mode === "dark" ? "#2ECA45" : colorCodes.salametti,
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 16,
      height: 16,
      transform: "translate(20%,20%)",
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const handleSession = (e) => {    
    e.preventDefault();
    setSessionName(e.target.value);
  }

  const handleUpdate = () => {
    if (sessionName?.length < 3) {
      setNameValidation(t("session_placeholder_text") + " " + t("name_length_validation_msg"));
    } 
    else if (prevSessionName !== sessionName && sessionNames?.includes(sessionName))
    {
      setNameValidation(t("duplicate_session_name_msg"));
    }
    else {
      closeModel();    
      const data = {
        trackingId: sessionData.trackingId,
        sessionName: sessionName,
        trackingType: isPublic ? sessionType.public: sessionType.private,
      }
      const showPopUp = (msg: string) => {
        setPopType(msg);
        setIsModelOpen(true);
      }
      editSessions(data).then((res) => {
        if (res && res?.status) {
          closeModel();
          showPopUp(POP_TYPES?.SUCCESS);
          setData(t("update_session_success_msg"));
          setNewData((current: boolean) => !current);
        } 
        else if (
          res.result.response.status === apiResponseCodes.invalidRequest &&
          res.result.response.data === invalidToken) {
          showPopUp(POP_TYPES.ERROR);
          setData(t("authentication_error_msg"));
          setTimeout(() => {
            signOut();
          }, 2000);
        } 
        else {
          showPopUp(POP_TYPES?.ERROR);
          setData(t("update_session_error_msg"));
        }
      })
    }
  }

  useEffect(() => {
    setIsPublic(sessionData?.type)
  }, [])
  
  useEffect(() => {
    setPrevSessionName(sessionName);
  }, [sessionData])

  return (
    <div className="popup"  style={{ alignItems: "center" }}>
      <Stack direction="row" spacing={1} alignItems="center">
        <StyledTypography variant="body1" >
          {t("session_name_text")}:
        </StyledTypography>                
        <StyledTextField    
          autoFocus={focus}
          focused={focus}
          placeholder={t("session_placeholder_text")}
          variant="outlined"
          value={sessionName}
          error={nameValidation?.length > 0 ? true : false}
          helperText={nameValidation}
          onChange={handleSession}
          onClick={() => setFocus(true)}
        />
      </Stack><br/>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography>{t("private_text")}</Typography>
        { disableSwitch &&
          <span>
            <StyledSwitch
              checked={isPublic}          
              onChange={() => setIsPublic(!isPublic)}
            />    
          </span>    
        } 
        { !disableSwitch &&
          <StyledSwitch            
            checked={isPublic}
            onChange={() => setIsPublic(!isPublic)}
          />    
        }
        <Typography>{t("public_text")}</Typography>
      </Stack><br/>
      {disableSwitch &&
        <StyledNoteTypography>
          {t("cannot_switch_to_public_text")}          
        </StyledNoteTypography>
      }
      { !disableSwitch && !isPublic &&
        <StyledNoteTypography>
          {t("cannot_switch_to_private_text")}          
        </StyledNoteTypography>
      }
      <br/><br/>
      
      <Stack spacing={2} direction="row" alignSelf="center">        
        <ThemeProvider theme={theme}>          
          <StyledUpdateButton 
            variant="contained"           
            onClick={handleUpdate}
          >      
            {t("update_button_text")}
          </StyledUpdateButton>          
        </ThemeProvider>        
        <StyledCancelButton 
          variant="outlined" 
          sx={{
            "&:hover": {
              borderColor: colorCodes?.darkCharcoal,
              backgroundColor: colorCodes?.darkCharcoal
            }
          }}          
          onClick={() => closeModel()}
        >
          {t("cancel_button_text")}
        </StyledCancelButton>        
      </Stack>
    </div>
  );
}

export default EditSession;