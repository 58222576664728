
// Bullitt google map license key
export const BullittGoogleMapKey = process.env.REACT_APP_BULLITT_GOOGLEMAP_KEY;

// fcm vapID for getting fcm token
export const vapIdKey = process.env.REACT_APP_VAPID_KEY;

// for DEV environment base URL's
// export const serverUrl= "https://cloud-userservice1-246pqtymlq-uc.a.run.app/";
// export const serverTrackingURL= "https://tracking-service-backend-zidi53gvka-uc.a.run.app/";

// for QA environment  base URL's
export const serverUrl = process.env.REACT_APP_QA_SERVER_URL;
export const serverTrackingURL = process.env.REACT_APP_QA_TRACKING_URL;

// authentication base URL for both dev and qa environment
export const authURl = process.env.REACT_APP_AUTH_URL;

export const authBaseURL = process.env.REACT_APP_AUTH_BASE_URL

//authentication API key
export const apikey = process.env.REACT_APP_API_KEY;

// fcm token
export const is_token = process.env.REACT_APP_FCM_TOKEN;

// application routing configuration
export const pageNavPaths = {
  home: "/",
  liveTracking: "/livetracking",
  profile: "/profile",
  preferences: "preferences",
  profileDetails: "/profile/livetracking",
  contacts: "/contacts",
  login: "/login",
  notAuthorized: "/not-authorized",
  locatoin:"/loc/:coordinates",
  subsrciptionURL: process.env.REACT_APP_SUBSCRIPTION_URL
}

// FCM configuration keys
export const googleFcmConfig = {
  apiKey: process.env.REACT_APP_FCM_API_KEY,
  authDomain: process.env.REACT_APP_FCM_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FCM_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FCM_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FCM_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_FCM_APP_ID,
  measurementId: process.env.REACT_APP_FCM_MEASUREMENT_ID
};

