import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { getStorageValue, toHoursAndMinutes } from "../../../shared/utils/common";
import { localStorageNames } from "../../../shared/utils/Constants";
import { pageNavPaths } from "../../../shared/utils/Config";

interface ICoordinates {
  trackingId?: string;
  coordinates?: { lat: number; lng: number }[];
}
interface ISession {
  durationMin?: string;
  durationSum?: number;
  sessionCount?: number;
  sessionViews?: number;
  _id?: number;
}
interface IUserProfile {
  profile?: string;
  sos?: string;
  tracking?: string;
}

interface IUserPreference {
  Miles?: boolean;
}

interface IUserStateProps {
  sessionsCoordinates: ICoordinates[];
  userProfile: IUserProfile;
  userPreference: IUserPreference;
  session: ISession;
}

export default function UserStats({userProfile, userPreference, session, sessionsCoordinates }:IUserStateProps) {
  const { t } = useTranslation();
  const [userName, setUserName] = useState("");
  const [totalTrekDistance, setTotalTrekDistance] = useState(0)
  const [totalDistance, setTotalDistance] = useState(0)
  const nameArray = userName?.split(" ");
  const firstInitial = nameArray[0]?.charAt(0);
  const lastInitial = nameArray?.length > 1 ? nameArray[nameArray?.length - 1]?.charAt(0) : "";
  const initials = firstInitial + lastInitial;

  React.useEffect(() => {
    const userDetails = JSON.parse(getStorageValue(localStorageNames?.loginDetails));
    setUserName(userDetails?.userName);

    const checkMapsInterval = setInterval(() => {
      if (window.google && window.google.maps) {
        clearInterval(checkMapsInterval);
        calculateTrackDistance();
      }
    }, 1000);

    return () => {
      clearInterval(checkMapsInterval);
    };
  }, [sessionsCoordinates]);

  let origin;
  let destination;

  const setCoordinates = (lat: number, lng: number) => {
    return new window.google.maps.LatLng(lat, lng);
  }


  const calculateTrackDistance = ()=>{
    let totalDistanceCovered = 0;
    sessionsCoordinates && sessionsCoordinates?.map((session) => {
      let sessionDistance = 0;
      if (session?.coordinates?.length > 0) {
        session?.coordinates?.map((plot, j) => {
          if (j === 0) {
            origin = setCoordinates(plot?.lat, plot?.lng);
          }
          if (j > 0) {
            destination = setCoordinates(plot?.lat, plot?.lng);
            sessionDistance = sessionDistance + 
                          window.google.maps.geometry.spherical.computeDistanceBetween(origin, destination);
            origin = setCoordinates(plot?.lat, plot?.lng);
          }
        })
        totalDistanceCovered = totalDistanceCovered + sessionDistance;
      }
    })
    setTotalDistance(totalDistanceCovered);
  }

  React.useEffect(() => {
    setTotalTrekDistance(() => userPreference?.Miles ? 
      (totalDistance * 0.001 * 0.62137) : 
      (totalDistance * 0.001)
    )
  }, [totalDistance])

  const handleSubscriptionClick = () => {       
    window.location.href = pageNavPaths?.subsrciptionURL;
  }

  return (
    <>
      <div className="profile_data">
        <div className="title">
          <h1 className="user_name">{userName}</h1>
          <h2>{t("profile_text")}</h2>
        </div>
        <div className="stats">
          <p className="stats_title">{t("stats_header_text")}</p>
          <p>{t("sessions_header_text")} {session ? session?.sessionCount : 0}</p>
          <p>
            {t("distance_header_text") + " "}
            {sessionsCoordinates ? parseFloat((totalTrekDistance).toString()).toFixed(2) + " " : 0}
            {userPreference?.Miles &&
              <span>
                {(totalTrekDistance > 1) ? t("miles_text") : t("mile_text")}
              </span>
            }
            {!userPreference?.Miles &&
              <span>
                {(totalTrekDistance > 1) ? t("kilometers_text") : t("kilometer_text")}
              </span>
            }
          </p>
        </div>
        <div className="stats2">
          <p>{t("sessions_views_header_text")} {session ? session?.sessionViews : 0}</p>
          <p>{t("time_header_text")} {session ? toHoursAndMinutes(session?.durationSum, t("hoursText"), t("minsText")) : 0}</p>
          <p>{t("longest_session_header_text")} {session ? toHoursAndMinutes(session?.durationMin, t("hoursText"), t("minsText")) : 0}...</p>
        </div>
        <div className="mobile-stats">        
          <table className="mobile-stats">          
            <tbody>
              <tr className="mobile-stats">
                <th className="mobile-stats">{t("sessions_header_text")}</th>
                <td className="mobile-stats">{session ? session?.sessionCount : 0}</td>
              </tr>
              <tr className="mobile-stats">
                <th className="mobile-stats">{t("distance_header_text")}</th>
                <td className="mobile-stats">                              
                  {sessionsCoordinates ? parseFloat((totalTrekDistance).toString()).toFixed(2) : 0}                
                  {userPreference?.Miles &&
                  <span>
                    {(totalTrekDistance > 1) ? t("miles_text") : t("mile_text")}
                  </span>
                  }
                  {!userPreference?.Miles &&
                  <span>
                    {(totalTrekDistance > 1) ? t("kilometers_text") : t("kilometer_text")}
                  </span>
                  }              
                </td>
              </tr>          
              <tr className="mobile-stats">
                <th className="mobile-stats">{t("sessions_views_header_text")}</th>
                <td className="mobile-stats">{session ? session?.sessionViews: 0}</td>
              </tr>  
              <tr className="mobile-stats">
                <th className="mobile-stats">{t("time_header_text")}</th>
                <td className="mobile-stats">{session ? toHoursAndMinutes(session?.durationSum,t("hoursText"), t("minsText")) : 0}</td>
              </tr> 
              <tr className="mobile-stats">
                <th className="mobile-stats">{t("longest_session_header_text")}</th>
                <td className="mobile-stats">{session ? toHoursAndMinutes(session?.durationMin, t("hoursText"), t("minsText")) : 0}...</td>
              </tr>  
            </tbody>       
          </table>
        </div>  
        <div className="user_details" onClick={handleSubscriptionClick}>          
          <div className="profile_img">
            <h2>{initials}</h2>
          </div>
          <div>
            <p>{t("profile_text")} : {userProfile?.profile === "Active" ? t("active_text") : t("in_active_text")}</p>
            <p>{t("tracking_text")} : {userProfile?.tracking === "Active" ? t("active_text") : t("in_active_text")}</p>
            {userProfile?.sos && <p>{t("sos_header_text")} : {userProfile?.sos} </p>}
          </div>
        </div>
      </div>
    </>
  )
}