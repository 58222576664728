const api = "api/";
const userService = "trackingservice/";
const trackingService = "trackingservice/";
const apiUserService = `${api}${userService}`
const apiTrackingService = `${api}${trackingService}`
const health = "health"

export const apiPath = {
  GET_CONTACT_DETAILS: `${trackingService}tracking-contactList/`,
  EDIT_CONTACT_DETAILS: `${trackingService}update-trackingContactList`,
  DELETE_CONTACT_DETAILS: `${trackingService}del-trackingContactList`,
  ADD_CONTACT: `${trackingService}add-trackingContactList`,
  TRACKING_DETAILS: `${trackingService}fcm-tracking-token`,
  GET_PREFERENCES: `${trackingService}webconfig/`,
  SET_PREFERENCES: `${trackingService}add-update-webconfig`,
  TRACKING_TIME_LINE: `${trackingService}tracking-timeLine/`,
  DELETE_SESSION: `${trackingService}del-trackingSession`,
  GET_NOTIFICATIONS: `${trackingService}get-readNotification/`,
  READ_NOTIFICATIONS: `${trackingService}readNotification/`,
  USER_SIGN_IN: "token?grant_type=password",
  USER_SIGN_OUT: "logout",
  EDIT_SESSION: `${trackingService}update-tracking-preference`,
  GET_SESSION_DATA: `${trackingService}get-timeline-data-trackId/`,
  GET_LOGGED_IN_USER_INFO: `${health}/auth?jwt`,
  UPDATE_REFRESH_TOKENS_N_COOCKIES:"token?grant_type=refresh_token",
  VERIFY_TOKENS:`${health}/auth`
};
