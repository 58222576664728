import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { fontFamily } from "../../../shared/styles/styles.config";
import { useTranslation } from "react-i18next";
import "./UserHistory.css";
import Share_icon from "../../../shared/icons/shareBlack.svg"
import DownloadIcon from "../../../shared/icons/Download.svg"
import EditIcon from "../../../shared/icons/edit.svg"
import { pageNavPaths } from "../../../shared/utils/Config";
import { localStorageNames, apiResponseCodes, invalidToken, POP_TYPES, trackingType, sessionType } from "../../../shared/utils/Constants";
import Tooltip from "@mui/material/Tooltip";
import { colorCodes } from "../../../shared/styles/styles.config";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import GroupsIcon from "@mui/icons-material/Groups";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Stack } from "@mui/material";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { gpxFileDownloader } from "../../../shared/utils/GpxFileDownload";
import PopUp from "../../../shared/components/PopUp";
import { getStorageValue, isPrivateSession, isPublicSession, signOut, toHoursAndMinutes, isSessionExpired, timeDiff, getTranslatedMonth, monthNumberToTranslationMap, isCoordinatesAreZero } from "../../../shared/utils/common";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import Paginate from "../../../shared/components/Paginate";
import DeleteSession from "./deleteSession";
import { getTrackingTimeLine } from "../services/ProfileServices";
import { styled } from "@mui/system";

const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: colorCodes.startlingOrange,
          color: colorCodes.white,
          border: `1px solid ${colorCodes.startlingOrange}`
        },
      },
    },
  },
});

const StyledTable = styled("div")`
@media (max-width: 900px) {
  overflow-x: auto;
  width: 95%;    
}

::-webkit-scrollbar {
  height: 6px;
  background-color: ${colorCodes.alto};
}

::-webkit-scrollbar-thumb {
  background-color: ${colorCodes.gray};
  border-radius: 10px;
}

`;

const StyledTableBody = styled("tbody")`
display: block;
overflow-y: auto;
max-height: 50vh;

::-webkit-scrollbar {
  width: 6px;
  background-color: ${colorCodes.alto};
}

::-webkit-scrollbar-thumb {
  background-color: ${colorCodes.gray};
  border-radius: 10px;
}
`;

const TableStyles = {
  position: "relative",
  display: "flex",
  justifyContent: "center",
  width: "100%",
  textAlign: "center",
  alignSelf: "center",
  color: colorCodes.brown,    
  fontFamily: fontFamily.Roboto,
  fontWeight: "400",
  fontSize: "15px",
  lineHeight: "40px",
  letterSpacing: "0.2px",
} as React.CSSProperties;

const live_dot_styles = {
  color: "e3602c",
  height: "8px",
  marginLeft: "-10px"
} as React.CSSProperties;

function UserHistory({ tableData, totalRecords, userProfile, refreshData, userName }) {
  const [selectedSessions, setSelectedSessions] = useState<Number[]>([]);
  const [isModelOpen, setIsModelOpen] = useState(true);
  const [popType, setPopType] = useState("");
  const [selectedCoordinates, setSelectedCoordinates] = useState([]);
  const [downloadStatus, setDownloadStatus] = useState<Boolean>(false);
  const [selectedSessionNames, setSelectedSessionNames] = useState([]);
  const [disableSwitch, setDisableSwitch] = useState(false);
  const [isZipDownload, setisZipDownload] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [sessionDetail, setSessionDetail] = useState({});
  const [currentData, setCurrentData] = useState([]);
  const [deleteSessionPopUp, setDeleteSessionPopUp] = React.useState<boolean | false>(false);
  const [data, setData] = React.useState("");
  const [newData, setNewData] = React.useState(false);
  const [profile, setUserprofile] = useState({});
  const [sessionNames, setSessionNames] = useState([]);
  const [selectedSessionUserIds, setSelectedSessionUserIds] = useState([]);   
  const [sessionTracking, setSessionTracking] = useState("");  
  const [isPrivateSessionSelected, setIsPrivateSessionSelected] = useState(false);
  const [selectedPrivateSessions, setSelectedPrivateSessions] = useState([])

  let sessionsArr = [];

  const navToSessionDetail = (data: any) => {    
    newData ?
      navigate(`${pageNavPaths.profileDetails}?id=${data?.trackingId}`,
        {
          state:
          {
            session: data,
            sessionNames: sessionNames,
            userProfile: profile,
            sessionTracking: data?.trackingId
          }
        })
      :
      navigate(`${pageNavPaths.profileDetails}?id=${data?.trackingId}`,
        {
          state:
          {
            session: data,
            sessionNames: sessionNames,
            userProfile: userProfile,
            sessionTracking: data?.trackingId
          }
        })
    localStorage.setItem("sessionData", JSON.stringify(data));
  }

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    setDeleteSessionPopUp(false);
    setIsModelOpen(false);
    setDownloadStatus(false)
    setisZipDownload(false);
  }

  const handleCheckClick = (i, trackingId, sessionName, coordinates, userId, sType) => {
    setSelectedSessions(pl => {
      if (pl.includes(trackingId)) {
        return pl.filter(item => item !== trackingId)
      } else {
        return [...pl, trackingId]
      }
    })

    sType === sessionType?.private && setSelectedPrivateSessions((pl)=>{
      if (pl.includes(trackingId)) {
        return pl.filter(item => item !== trackingId)
      } else {
        return [...pl, trackingId]
      }
    })

    setSelectedCoordinates((pv) => {
      if (selectedSessions?.includes(trackingId)) {
        const index = selectedSessions?.indexOf(trackingId)
        pv = pv?.filter((value, i) => i !== index);
        return pv?.length > 0 ? [...pv] : []
      } else {
        return [...pv, coordinates]
      }
    })

    setSelectedSessionNames((pl)=>{
      if(pl.includes(sessionName)) {
        return pl.filter(item => item !== sessionName)
      } else {
        return [...pl, sessionName]
      }
    })

    setSelectedSessionUserIds((prevIds)=>{
      if(selectedSessions.includes(trackingId)){
        const index = selectedSessions?.indexOf(trackingId)
        return prevIds?.filter((value, i) => i !== index);
      }else{
        return [...prevIds,userId]
      }
    })
    localStorage.setItem("scrollElement", `checkbox${i}`);
  }

  const handleDownloadMany = () => {
    selectedCoordinates?.length > 1 && setisZipDownload(true)
    setPopType(POP_TYPES?.LOADER)
    setIsModelOpen(true);
    gpxFileDownloader(selectedCoordinates, selectedSessionNames, setDownloadStatus)
  }

  const handleSingleGpxDownload = (coordinates, sessionName) => {
    setPopType(POP_TYPES?.LOADER)
    setIsModelOpen(true);
    gpxFileDownloader([coordinates], [sessionName], setDownloadStatus)
  }

  const handleActionClick = (action: string, id) => {
    setPopType(action);    
    setSessionTracking(id)
    setIsModelOpen(true);    
  }

  const deleteSingleSession = (row)=>{
    setSelectedSessions([row?.trackingId])
    setSelectedSessionUserIds([row?.userId])
    setDeleteSessionPopUp(true);
  }

  const clearPrevSelectedSession = ()=>{
    setSelectedSessions([])
    setSelectedSessionUserIds([])
  }

  const loggedInUserMobile = JSON.parse(getStorageValue(localStorageNames.loginDetails))?.mobileNo;

  const timeLineSessionData = (userId, size) => {
    getTrackingTimeLine(userId, size).then((res) => {
      setPopType(POP_TYPES.LOADER);
      setIsModelOpen(true);
      if (res.status && res?.result?.timeline?.length > 0) {
        setCurrentData(res?.result?.timeline);
        setPopType("");
        setUserprofile(res?.result?.userProfile);        
      } 
      else if (res.result.response.status === apiResponseCodes.invalidRequest &&
              res.result.response.data === invalidToken) {
        setPopType(POP_TYPES.ERROR);
        setData(t("authentication_error_msg"));
        setTimeout(() => {
          signOut();
        }, 2000);
      } 
      else {
        setPopType("");
      }
    })
  }

  const handleEdit = (action: string, session: any) => {                
    setDisableSwitch(isSessionExpired(session));
    setSessionDetail({
      name: session?.sessionName,
      type: isPublicSession(session?.trackingType),
      trackingId: session?.trackingId
    });
    setPopType(action);
    setIsModelOpen(true);
  }

  useEffect(() => {
    downloadStatus && setPopType(POP_TYPES?.DOWNLOAD)
  }, [downloadStatus]);

  useEffect(() => {
    setCurrentData(tableData);
  }, [tableData]);

  useEffect(() => {
    selectedPrivateSessions.length > 0 ? setIsPrivateSessionSelected(true) : setIsPrivateSessionSelected(false)
  }, [selectedPrivateSessions])
  


  //for extracting session names
  useEffect(() => {
    sessionsArr = currentData.map((obj) => {
      return obj?.sessionName;
    });
    setSessionNames(sessionsArr);
  }, [currentData]);

  useEffect(() => {
    // If delete/edit the session get timeline api will call with new data
    refreshData(newData);
  }, [newData])


  const showPage = (value: number) => {
    timeLineSessionData(loggedInUserMobile, value);
  }

  return (
    <>
      <StyledTable>
        <table className="history_table">
          <React.Fragment>
            <thead>
              <tr>
                <th>{t("session_heading_text")}</th>
                <th>{t("owner_header_text")}</th>
                <th>{t("created_header_text")}</th>
                <th>{t("duration_header_text")}</th>
                <th>{t("start_location_header_text")}</th>
                <th>{t("end_location_header_text")}</th>
                <th>{t("share_preference_text")}</th>
                <th>
                  <Stack
                    sx={
                      selectedSessions?.length > 1 ?
                        { flexDirection: "row", justifyContent: "space-around", alignItems: "center", paddingRight: "1rem" } :
                        { flexDirection: "row", justifyContent: "space-between", alignItems: "center" }
                    }
                  >
                    {t("actions_text")}
                    {selectedSessions?.length > 1 &&
                      <>
                        <ThemeProvider theme={theme}>
                          <Tooltip title={isPrivateSessionSelected ? t("disabledDownloadAll_text") : t("downloadAll_text")} placement="bottom" arrow>
                            <DownloadForOfflineIcon onClick={isPrivateSessionSelected ? null :handleDownloadMany} className={isPrivateSessionSelected ? "disabled-share-icon": "hand_cursor"} />
                          </Tooltip>
                        </ThemeProvider>
                        <ThemeProvider theme={theme}>
                          <Tooltip title={t("delete_multiple_sessions_text")} placement="top" arrow>
                            <DeleteOutlinedIcon className="hand_cursor" onClick={() => { setDeleteSessionPopUp(true) }} />
                          </Tooltip>
                        </ThemeProvider>
                      </>
                    }
                  </Stack>
                </th>
              </tr>
            </thead>

            <StyledTableBody 
              sx={{
                maxHeight: currentData?.length > 0 ? "50vh" : "10vh"
              }}
              className="history_table_body"
            >
              {currentData?.length > 0 &&
                currentData?.map((row, i) => {
                  const currentMonthNumber = new Date(row?.createdAt)?.getMonth();
                  const monthTranslationString = monthNumberToTranslationMap[currentMonthNumber];
                  return (
                    <tr key={i}>
                      <td>
                        <div>
                          <div className="checkbox-container">
                            <input
                              type="checkbox"
                              className="checkbox"
                              checked={selectedSessions.includes(row?.trackingId)}
                              id={`checkbox${i}`}
                              onChange={() => handleCheckClick(i, row?.trackingId, row?.sessionName, row?.coordinates,row?.userId,row?.trackingType)}
                            />
                            <label htmlFor={`checkbox${i}`}></label>
                          </div>
                          <span onClick={() => navToSessionDetail(row)} className="hand_cursor" title={row?.sessionName}>
                            {row?.sessionName?.length > 20 ? row?.sessionName?.substring(0,20) + "..." : row?.sessionName}
                          </span>
                        </div>
                      </td>
                      <td className="hand_cursor" onClick={() => navToSessionDetail(row)} title={(userName?.length > 0) ? (userName) : ("+" + row?.userId)}>{(userName?.length > 0) ? (userName) : ("+" + row?.userId)}</td>                      
                      <td className="hand_cursor" style={{whiteSpace:"pre-line"}} onClick={() => navToSessionDetail(row)}>{getTranslatedMonth(row?.createdAt,t(monthTranslationString))}</td>
                      <td
                        className="hand_cursor"
                        onClick={() => navToSessionDetail(row)}
                      >
                        {toHoursAndMinutes(
                          row.coordinates.length > 1 ?
                            Math.round(
                              Math.min(
                                row?.duration,
                                timeDiff(
                                  row.coordinates[0].trackTime,
                                  row.coordinates[row.coordinates.length - 1]
                                    .trackTime
                                )
                              )
                            )
                            :
                            0,
                          t("hoursText"),
                          t("minsText")
                        )}
                      </td>
                      <td onClick={() => navToSessionDetail(row)} className="hand_cursor" title={`${row?.coordinates[0]?.lat}, ${row?.coordinates[0]?.lng}`}>                        
                        {row?.coordinates[0]?.lat?.toString()?.substring(0, 9)}, <br/>{row?.coordinates[0]?.lng?.toString()?.substring(0, 9)}
                      </td>
                      {row.trackStatus === trackingType.open ?
                        <td className="hand_cursor" onClick={() => navToSessionDetail(row)}>{t("live_text")} <FiberManualRecordIcon style={live_dot_styles} /></td> :
                        isCoordinatesAreZero(row?.coordinates[row?.coordinates?.length - 1]?.lat,row?.coordinates[row?.coordinates?.length - 1]?.lng) ?
                          <td>{t("locationNotAvailableText")}</td>:
                          <td onClick={() => navToSessionDetail(row)} className="hand_cursor" title={`${row?.coordinates[row?.coordinates?.length - 1]?.lat}, ${row?.coordinates[row?.coordinates?.length - 1]?.lng}`}>                          
                            {row?.coordinates[row?.coordinates?.length - 1]?.lat?.toString()?.substring(0, 9)}, <br/>{row?.coordinates[row?.coordinates?.length - 1]?.lng?.toString()?.substring(0, 9)}
                          </td>
                      }
                      <td valign="middle" className="hand_cursor" onClick={() => navToSessionDetail(row)}>
                        <div className="share_preferences">
                          {(isPublicSession(row?.trackingType)) &&
                            <ThemeProvider theme={theme}>
                              <Tooltip title={t("publicSession_text")} placement="top" arrow>
                                <GroupsIcon className="lock_icon" />
                              </Tooltip>
                            </ThemeProvider>
                          }
                          {(isPrivateSession(row?.trackingType)) &&
                            <ThemeProvider theme={theme}>
                              <Tooltip title={t("privateSession_text")} placement="top" arrow>
                                <LockOutlinedIcon className="lock_icon" />
                              </Tooltip>
                            </ThemeProvider>
                          }
                          {isPublicSession(row?.trackingType) ? t("public_text") : t("private_text")}
                        </div>
                      </td>
                      <td className="share_icons">
                        <ThemeProvider theme={theme}>
                          {isPublicSession(row?.trackingType) &&
                            <Tooltip title={t("share_session")} placement="top" arrow>
                              <img
                                className="share-icon"
                                src={Share_icon}
                                width="16px"
                                height="17px"
                                alt={t("share_text")}
                                onClick={() => handleActionClick(POP_TYPES.SHARE, row?.trackingId) }
                              />
                            </Tooltip>
                          }
                          {isPrivateSession(row?.trackingType) &&
                            <Tooltip title={t("privateShareSession_text")} placement="top" arrow>
                              <img className="disabled-share-icon" src={Share_icon} width="16px" height="17px" alt={t("share_text")} />
                            </Tooltip>
                          }
                          {isPublicSession(row?.trackingType) &&
                          <Tooltip title={t("download_session_text")} placement="top" arrow>
                            <img
                              className="icons-class"
                              src={DownloadIcon}
                              alt={t("download_text")}
                              width="17px"
                              height="19px"
                              onClick={() => { handleSingleGpxDownload(row?.coordinates, row?.sessionName) }}
                            />
                          </Tooltip>}
                          {isPrivateSession(row?.trackingType) &&
                          <Tooltip title={t("privateDownloadSession_text")} placement="top" arrow>
                            <img
                              className="icons-class disabled-share-icon"
                              src={DownloadIcon}
                              alt={t("download_text")}
                              width="17px"
                              height="19px"
                            />
                          </Tooltip>}
                          <Tooltip title={t("edit_session_text")} placement="top" arrow>
                            <img
                              className="icons-class"
                              src={EditIcon}
                              alt={t("edit_text")}
                              width="20px"
                              height="19px"
                              onClick={() => { handleEdit(POP_TYPES?.EDIT, row) }}
                            />
                          </Tooltip>
                          <ThemeProvider theme={theme}>
                            <Tooltip title={t("delete_session")} placement="top" arrow>
                              <DeleteOutlinedIcon
                                className="icons-class delete_icon"
                                onClick={() => { deleteSingleSession(row) }} />
                            </Tooltip>
                          </ThemeProvider>
                        </ThemeProvider>
                      </td>
                    </tr>
                  );
                })
              }
            </StyledTableBody>
          </React.Fragment>
        </table>
        { currentData?.length < 1 &&
            <div style={TableStyles}>{t("no_data_found")}</div> 
        }
      </StyledTable><br/>
      {(totalRecords > 10) &&
        <div className="pages">
          <Paginate totalRecords={totalRecords} rowsPerPage={10} currentPage={showPage} trackingStatus=""/>
        </div>
      }
      {
        deleteSessionPopUp &&
        <DeleteSession
          isModelOpen={true}
          selectedSessionUserIds={selectedSessionUserIds}
          handleClose={handleClose}
          setPopType={setPopType}
          setIsModelOpen={setIsModelOpen}
          setData={setData}
          selectedSessions={selectedSessions}
          setNewData={setNewData}
          loggedInUserMobile = {loggedInUserMobile}
          clearPrevSelectedSession ={clearPrevSelectedSession}
        />
      }
                
      <PopUp
        isModelOpen={popType && isModelOpen}
        type={popType}
        handleClose={handleClose}
        downloadStatus={downloadStatus}
        sessionNames={sessionNames}
        sessionData={sessionDetail}
        disableSwitch={disableSwitch}
        isZipDownload={isZipDownload}
        message={data}
        setPopType={setPopType}
        setIsModelOpen={setIsModelOpen}
        setData={setData}
        setNewData={setNewData}
        trackingId={sessionTracking}
      />
    </>
  )
}

export default UserHistory;