import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PopUp from "../../shared/components/PopUp";
import {
  POP_TYPES,
  localStorageNames,
  mapTypes,
} from "../../shared/utils/Constants";
import { pageNavPaths } from "../../shared/utils/Config";
import Live_Marker_White from "../../shared/icons/Live_white.svg";
import Live_Marker_Black from "../../shared/icons/Live_black.svg";
import { getStorageValue, setStorageValue } from "../../shared/utils/common";
import { styled } from "@mui/material";
import { useTranslation } from "react-i18next";

const StyledContainer = styled("div")`
& .gm-style-iw{
  max-width: 100% !important;
}
`
function LocationFinder() {
  const [popType, setPopType] = React.useState(POP_TYPES.LOADER);
  const [isModelOpen, setIsModelOpen] = React.useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { coordinates } = useParams();
  const [lat, lng] = coordinates.split(",");

  const mapContainerRef = useRef(null);

  // Validate coordinates coming in params
  const validateCoordinates = () => {
    if (isNaN(parseFloat(lat)) || isNaN(parseFloat(lng))) {
      setPopType(POP_TYPES.ERROR);
      setErrorMsg(
        t("invalidCoordinatesText")
      );
      return false;
    }
    if (Math.abs(parseFloat(lat)) > 90 || Math.abs(parseFloat(lng)) > 180) {
      setPopType(POP_TYPES.ERROR);
      setErrorMsg(
        t("outOfRangeCoordinatesText")
      );
      return false;
    }
    return true;
  };

  // Calculate height of remainig space otherthan header
  const calculateMapHeight = () => {
    const headerHeight = 120; // Default header height
    const viewportHeight = window.innerHeight;
    const mapHeight = viewportHeight - headerHeight;
    mapContainerRef.current.style.height = `${mapHeight}px`;
  };

  const handleClose = async () => {
    try {
      setIsModelOpen(false);
      navigate(pageNavPaths.home);
    } catch (err) {
      setErrorMsg(err);
    }
  };

  // Get Map view type
  const isTerrainView = (map) => {
    return (
      map?.mapTypeId === mapTypes?.terrain ||
      map?.mapTypeId === mapTypes?.roadmap
    );
  };

  // Get marker based on map type ( Terain/Satellite )
  const getMarker = (map) => {
    return isTerrainView(map) ? Live_Marker_Black : Live_Marker_White;
  };

  useEffect(() => {
    if (!validateCoordinates()) {
      // Coordinates are invalid, don't initialize the map
      return;
    }

    calculateMapHeight();
    window.addEventListener("resize", calculateMapHeight);

    const checkMapsInterval = setInterval(() => {
      if (window.google && window.google.maps) {
        clearInterval(checkMapsInterval);
        renderMap();
      }
    }, 1000);

    return () => {
      // Cleanup when the component unmounts
      clearInterval(checkMapsInterval);
      window.removeEventListener("resize", calculateMapHeight);
    };
  }, [lat, lng]);

  const renderMap = ()=>{

    // Center and Marker coordinates
    const defaultCenter = {
      lat: parseFloat(lat),
      lng: parseFloat(lng),
    };

    // Initialize Map
    const map = new window.google.maps.Map(document.getElementById("map"), {
      center: defaultCenter,
      zoom: 15,
      mapTypeId:
      getStorageValue(localStorageNames?.mapType) === mapTypes?.hybrid
        ? mapTypes?.hybrid
        : mapTypes?.terrain,
    });

    // Marker
    const marker = new window.google.maps.Marker({
      position: defaultCenter,
      map,
      icon: {
        url: getMarker(map),
        scaledSize: new window.google.maps.Size(55, 45),
      },
    });
    // Create a banner element with lat-lang
    const bannerElement = document.createElement("div");
    bannerElement.style.backgroundColor = "white";
    bannerElement.style.color = "black";
    bannerElement.style.fontWeight = "500";
    bannerElement.style.padding = "0px";
    bannerElement.style.borderRadius = "5px";
    bannerElement.innerText = `Latitude: ${lat}, Longitude: ${lng}`;

    // Create an InfoWindow with the banner content
    const infowindow = new window.google.maps.InfoWindow({
      content: bannerElement,
    });

    // Attach InfoWindow to the marker
    infowindow.open(map, marker);
    marker.addListener("click", () => {
      infowindow.open(map,marker)
    })
    setIsModelOpen(false);

    // Change marker based on Map type
    map?.addListener("maptypeid_changed", () => {
      setStorageValue(localStorageNames?.mapType, map?.getMapTypeId());
      marker &&
      marker?.setIcon({
        url: getMarker(map),
        scaledSize: new window.google.maps.Size(55, 45),
      });
    });
  }

  return (
    <StyledContainer>
      <div ref={mapContainerRef} id="map"></div>
      <PopUp
        isModelOpen={popType && isModelOpen}
        type={popType}
        handleClose={handleClose}
        message={errorMsg}
      />
    </StyledContainer>
  );
}

export default LocationFinder;
